<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card" v-if="$gate.isUser()">

              <div class="row">
                <div class="col-sm-1"></div>
                <div class="col-sm-10 text-center">
                    <br><h1>Selección de Fecha</h1><hr>
                    <h4>Ingrese mes y año de búsqueda...</h4><br>
                </div>
                <div class="col-sm-2" hidden>{{oldDate = new Date().getFullYear()-3}}</div>
              </div>
                <form @submit.prevent="sendDate">
                    <div class="form-row justify-content-center">
                        <div class="form-group col-auto">
                            <select class="form-control mb-2" v-model="form.mes" name="mes" id="mes" ref="mes" required>
                                <option value="" selected disabled>Seleccione un mes...</option>
                                <option value="1">Enero</option>
                                <option value="2">Febrero</option>
                                <option value="3">Marzo</option>
                                <option value="4">Abril</option>
                                <option value="5">Mayo</option>
                                <option value="6">Junio</option>
                                <option value="7">Julio</option>
                                <option value="8">Agosto</option>
                                <option value="9">Septiembre</option>
                                <option value="10">Octubre</option>
                                <option value="11">Noviembre</option>
                                <option value="12">Diciembre</option>
                            </select>
                        </div>
                        <div class="form-group col-auto">
                            <select class="form-control mb-2" v-model="form.anio" name="anio" id="anio" ref="anio" required>
                                <option value="" disabled selected>Seleccione un año...</option>
                                <option v-for="i in 3" v-bind:value=(i+oldDate) :key="i">{{i+oldDate}}</option>
                            </select>
                        </div>
                        <div class="form-group col-auto">
                            <button type="submit" class="btn btn-success mb-2">Buscar pagos&nbsp;<i class="fas fa-chevron-circle-right"></i></button>
                        </div>
                    </div>
                </form>
            </div>
            <!-- /.card -->
          </div>
        </div>
        <div v-if="!$gate.isUser()">
            <not-found></not-found>
        </div>
    </div>
  </section>
</template>
<script>
    export default {
        data () {
            return {
                editmode: false,
                users : {},
                mes: '',
                anio: '',
                form: new Form({
                    mes: '',
                    anio: '',
                })
            }
        },
        methods: {
          loadData(){
            this.$Progress.start();
            if(this.$gate.isAdmin()){
              axios.get("api/user").then(({ data }) => (this.users = data.data));
            }
            this.$Progress.finish();
          },
          sendDate(){
            this.$Progress.start();
            const mes = this.form.mes;
            const anio = this.form.anio;
            this.$router.push({path:'/detalle',query:{mes:mes,anio:anio}});
            this.$Progress.finish();
          },
        },
        mounted() {
            console.log('Component mounted.');
        },
        created() {
            this.$Progress.start();
            this.loadData();
            //axios.get("api/profile").then(({ data }) => (this.users = data.data));//(this.form.fill(data.data)));
            this.$Progress.finish();
        }
    }
</script>
