<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card" v-if="$gate.isAdmin()">
              <div class="row">
                <div class="col-sm-1"></div>
                <div class="col-sm-10 text-center">
                    <br><h1>Eliminar Consolidado</h1><hr>
                    <h4>Ingrese mes y año de pagos...</h4><br>
                </div>
                <div class="col-sm-2" hidden>{{oldDate = new Date().getFullYear()-3}}</div>
              </div>
              <div class="row">
                <div class="col-sm-4"></div>
                <div class="col-sm-4">
                    <form @submit.prevent="deleteData">
                        <div class="text-center">
                            <div class="form-group">
                                <select class="form-control mb-2 text-center form-control-sm" v-model="form.mes" name="mes" id="mes" ref="mes" required>
                                    <option value="" selected disabled>Seleccione un mes...</option>
                                    <option value="1">Enero</option>
                                    <option value="2">Febrero</option>
                                    <option value="3">Marzo</option>
                                    <option value="4">Abril</option>
                                    <option value="5">Mayo</option>
                                    <option value="6">Junio</option>
                                    <option value="7">Julio</option>
                                    <option value="8">Agosto</option>
                                    <option value="9">Septiembre</option>
                                    <option value="10">Octubre</option>
                                    <option value="11">Noviembre</option>
                                    <option value="12">Diciembre</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <select class="form-control mb-2 text-center form-control-sm" v-model="form.anio" name="anio" id="anio" ref="anio" required>
                                    <option value="" disabled selected>Seleccione un año...</option>
                                    <option v-for="i in 3" v-bind:value=(i+oldDate) :key="i">{{i+oldDate}}</option>
                                </select>
                            </div>
                            <div class="form-group text-center">
                                <br><button type="submit" class="btn btn-danger mb-2">Eliminar Consolidado</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-sm-4"></div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
                users : {},
                file: '',
                form: new Form({
                    mes: '',
                    anio: '',
                }),
            }
        },
        methods: {
            deleteData(){
                Swal.fire({
                    title: '¿Está seguro que desea eliminar este consolidado?',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si eliminar',
                    cancelButtonText: 'No eliminar'
                }).then((result) => {
                    if (result.value) {
                        this.form.delete('api/encuesta/DeleteEncuestador').then(()=>{
                            Swal.fire(
                            'Eliminado!',
                            'El consolidado fue eliminado.',
                            'success'
                            );
                        }).catch((data)=> {
                            Swal.fire("falló!", data.message, "warning");
                        });
                    }
                    this.form.mes = '';
                    this.form.anio = '';
                });

            },
        },
        mounted() {
            console.log('Component mounted.');
        },
        created() {
            this.$Progress.start();
            this.$Progress.finish();
        }
    }
</script>
