<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card" v-if="$gate.isAdmin()">
            <div class="card-header">
                <div class="card-tools">
                    <a class="btn btn-primary btn-sm" href="/files/Cargar_Pagos.csv" :download="currentDate()"
                        data-toggle="tooltip" data-html="true" data-placement="top"
                        title="<i>Archivo base para subir al sistema.<br> (No borrar ni cambiar nombres de encabezados.)</i>"
                        >
                        Descargar Archivo <i class="fas fa-question-circle"></i>
                    </a>
                </div>
            </div>
              <div class="row">
                <div class="col-sm-1"></div>
                <div class="col-sm-10 text-center">
                    <br><h1>Ingresar Consolidado</h1><hr>
                    <h4>Ingrese mes y año de pagos...</h4><br>
                </div>
                <div class="col-sm-2" hidden>{{oldDate = new Date().getFullYear()-3}}</div>
              </div>
              <div class="row">
                <div class="col-sm-4"></div>
                <div class="col-sm-4">
                    <form @submit.prevent="submitFile"> <!-- -->
                        <div class="text-center">
                            <div class="form-group">
                                <select class="form-control mb-2 text-center form-control-sm" v-model="form.mes" name="mes" id="mes" ref="mes" required>
                                    <option value="" selected disabled>Seleccione un mes...</option>
                                    <option value="1">Enero</option>
                                    <option value="2">Febrero</option>
                                    <option value="3">Marzo</option>
                                    <option value="4">Abril</option>
                                    <option value="5">Mayo</option>
                                    <option value="6">Junio</option>
                                    <option value="7">Julio</option>
                                    <option value="8">Agosto</option>
                                    <option value="9">Septiembre</option>
                                    <option value="10">Octubre</option>
                                    <option value="11">Noviembre</option>
                                    <option value="12">Diciembre</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <select class="form-control mb-2  text-center form-control-sm" v-model="form.anio" name="anio" id="anio" ref="anio" required>
                                    <option value="" disabled selected>Seleccione un año...</option>
                                    <option v-for="i in 3" v-bind:value=(i+oldDate) :key="i">{{i+oldDate}}</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <input type="file" name="file" id="file" accept=".csv"
                                required @change="handleFileUpload($event)" ref="myFileInput">
                                <small><p class="font-italic">*Si las columnas numéricas tienen decimales, favor usar <b>punto(.)</b>.</p></small>
                            </div>
                            <div>

                            </div>
                            <div class="form-group text-center">
                                <br><button type="submit" class="btn btn-success mb-2" name="submit">Ingresar Consolidado</button>
                            </div><!-- v-on:click="submitFile()" -->
                        </div>
                    </form>
                </div>
                <div class="col-sm-4"></div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
                users : {},
                file: '',
                form: new Form({
                    mes: '',
                    anio: '',
                }),

            }
        },
        methods: {
            currentDate() {
                var formatted_date = new Date().toJSON().slice(0,10);
                return 'Cargar_Pagos_'+formatted_date+'.csv';
            },
            handleFileUpload(event){
            this.file = event.target.files[0];
            },
            submitFile(){
                let formData = new FormData();
                formData.append('file', this.file);
                formData.append('fecha', this.form.mes + this.form.anio);//this.$route.query.mes + this.$route.query.anio
                if(this.file == ''){
                    Toast.fire({
                        icon: 'warning',
                        title: 'Seleccione un archivo'
                    });
                }else{
                    axios.post('api/encuesta/importCSVPago',
                    formData,{
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((response)=>{
                        Toast.fire({
                            icon: 'success',
                            title: response.data.message
                        });
                    }).catch(()=>{
                        Toast.fire({
                            icon: 'error',
                            title: 'Ocurrió un error!'
                        });
                    });
                    this.form.mes = '';
                    this.form.anio = '';
                    this.$refs.myFileInput.value = '';
                }
            },
        },
        mounted() {
            console.log('Component mounted.');
            $('[data-toggle="tooltip"]').tooltip();
        },
        created() {},
    }
</script>
