<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card" v-if="$gate.isUser()">
                <div class="card-body table-responsive">
                  <h2 class="text-center  font-italic">Datos Personales</h2><br>
                    <div class="row" >
                        <div class="col-sm-6">
                            <div class="list-group" v-for="user in pagosB" :key="user.id">
                                <ul class="list-group">
                                    <li class="list-group-item py-1"><b>RUT:</b> {{user.RUT_Encuestador}}</li>
                                    <li class="list-group-item py-1"><b>Nombre:</b> {{user.Nombre_Encuestador}}</li>
                                    <li class="list-group-item py-1"><b>Ciudad:</b> {{user.ciudad}}</li>
                                    <li class="list-group-item py-1"><b>Tipo Prestación:</b> {{user.tipo_prestacion}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="list-group" v-for="user in pagosB" :key="user.id">
                                <ul class="list-group">
                                    <li class="list-group-item py-1"><b>Tipo de Cuenta:</b> {{user.tipo_Cuenta}}</li>
                                    <li class="list-group-item py-1"><b>Banco:</b> {{user.banco}}</li>
                                    <li class="list-group-item py-1"><b>Número Cuenta:</b> {{user.numero_Cuenta}}</li>
                                    <li class="list-group-item py-1"><b>Tipo de Boleta:</b> {{user.tipo_Boleta}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-sm-12" v-show="pagosB.length == 0">
                            <ul class="list-group text-center">
                                <li class="list-group-item font-italic">*Sin datos en la fecha ingresada...</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr style="background-color:#c5bf2f">
                <div class="card-body table-responsive p-0">
                <p hidden id="rutD">78574400-4</p>
                <br><h2 class="text-center  font-italic">Datos Datavoz</h2><br>
                <table class="table table-condensed">
                  <thead>
                    <tr>
                        <th scope="col">Nombre Empresa</th>
                        <th scope="col">Rut Empresa</th>
                        <th scope="col">Dirección</th>
                        <th scope="col">Correo envío Boleta</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td><a href="http://www.datavoz.cl" target="_blank" class="text-dark" data-toggle="tooltip" title="Click para ir a la página" >STATCOM DATAVOZ SPA</a></td>
                        <td><a onclick="copyToClipboard('#rutD')" href="#" data-toggle="tooltip" title="Click para copiar" id="rutDatavoz"  class="text-dark">78.574.400-4</a></td>
                        <td><a href="https://goo.gl/maps/Ja91HYyvkLThKVLX8" target="_blank" class="text-dark" data-toggle="tooltip" title="Click para ver en el mapa" >Las Bellotas 199, Of. 114, Providencia</a></td>
                        <td><a onclick="copyToClipboard('#CorreoDatavoz')" href="#" class="text-dark" id="CorreoDatavoz" data-toggle="tooltip" title="Click para copiar correo">contacto@datavoz.cl</a></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr style="background-color:#c5bf2f">
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <br><h2 class="text-center font-italic">Detalle por cada boleta</h2><br>
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Código Estudio</th>
                      <th scope="col">Nombre Estudio</th>
                      <th scope="col">Jefe Terreno</th>
                      <th scope="col">Tipo Prestación</th>
                      <th scope="col">N° Prestaciones</th>
                      <th scope="col">Otros</th>
                      <th scope="col">Locomoción</th>
                      <th scope="col">Total Bruto</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="user in pagos" :key="user.id">
                      <td>{{user.id_estudio}}</td>
                      <td>{{user.NombreEstudio}}</td>
                      <td>{{user.Jefe_Terreno}}</td>
                      <td>{{user.tipo_prestacion}}</td>
                      <td>{{user.numero_prestaciones}}</td>
                      <td v-if="user.otros !== ''">{{parseInt(user.otros) | aMoneda}}.-</td>
                      <td v-else>$0.-</td>
                      <td v-if="user.locomocion !== ''">{{parseInt(user.locomocion) | aMoneda}}.-</td>
                      <td v-else>$0.-</td>
                      <td v-if="user.totalBruto !== ''">{{parseInt(user.totalBruto) | aMoneda}}.-</td><!--parseFloat(user.totalBruto).toFixed(0)-->
                      <td v-else>$0.-</td>
                    </tr>
                    <tr v-show="pagos.length == 0">
                        <td colspan="7" class="text-center font-weight-bold font-italic">Sin pagos en la fecha ingresada...</td>
                    </tr>
                  </tbody>
                </table>
              </div>
                <div class="row">
                    <div class="col-sm-12" :hidden="pagos.length == 0">
                        <br><h3 style="color: #ff0534; display: inline; text-shadow: 0.1em 0.1em 0.2em gray">&nbsp;<em><b>*Recuerde realizar una boleta por Estudio.</b></em></h3>
                        <p style="display: inline"><a href="https://homer.sii.cl/" target="_blank" rel="noopener noreferrer"><em>(Abrir página del SII)</em></a></p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-12">
                        <h4 class="text-center">¿Esta información es correcta para el pago?</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col-sm-6">
                        <form @submit.prevent="enviarRespuesta" class="text-center" >
                            <label class="radio-inline">
                                <input type="radio" v-model="form.estado" style="transform:scale(1.50);"
                                name="estado" id="estado" value="A" required :disabled="pagos.length == 0 || btnValue != ''" checked>&nbsp;&nbsp;SI
                            </label>
                            &nbsp;&nbsp;
                            <label class="radio-inline">
                                <input type="radio" v-model="form.estado" style="transform:scale(1.50);"
                                name="estado" id="estado" value="R" required :disabled="pagos.length == 0 || btnValue != ''">&nbsp;&nbsp;NO
                            </label>
                            <div v-show="form.estado === 'R'" class="form-group">
                                <br><textarea v-model="form.comentario" class="form-control" name="comentario" id="comentario" rows="2"
                                style="resize:none" placeholder="Ingrese su observación aquí..." :required="form.estado === 'R'"
                                :disabled="btnValue != ''"></textarea>
                            </div>
                            <div class="form-group">
                                <button type="submit" class="btn btn-success" :disabled="pagos.length == 0 || btnValue != ''">Enviar</button>
                            </div>
                        </form><br>
                    </div>
                    <div class="col-sm-3"></div>
                </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
        <div v-if="!$gate.isUser()">
            <not-found></not-found>
        </div>
    </div>
  </section>
</template>
<script>
    export default {
        data () {
            return {
                editmode: false,
                pagos : {},
                pagosB : {},
                pagosid : {},
                btnValue: '',
                form: new Form({
                    id : '',
                    estado: '',
                    comentario: '',
                    mes: this.$route.query.mes,
                    anio: this.$route.query.anio,
                })
            }
        },
        methods: {
            updateEncuesta(){
                this.$Progress.start();
                this.pagos.forEach((value, index) => {
                  axios.put('api/encuesta/updatePago/'+value.id, {estado: this.form.estado})
                  .then((response) => {
                      $('#addNew').modal('hide');
                      Toast.fire({
                        icon: 'success',
                        title: response.data.message
                      });
                      this.$Progress.finish();
                  })
                }),
                setTimeout(()=>{
                    this.$router.push({path:'/fecha'})
                },1000);
            },

            enviarRespuesta(){
                Swal.fire({
                    title: '¿Realizó una boleta por estudio?',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.$Progress.start();
                        this.btnValue = '1';
                        this.form.put('api/mail/html_email')
                        .then((response) => {
                            Toast.fire({
                            icon: 'success',
                            title: response.data.message
                            });
                            this.$Progress.finish();
                        });
                        setTimeout(()=>{
                            this.$router.push({path:'/fecha'})
                        },3000);
                    }
                });

            },

            laodPagos(){
                this.$Progress.start();
                axios.get("api/encuesta/selectPagos",{params: {mes: this.$route.query.mes, anio: this.$route.query.anio}})
                .then(({ data }) =>(this.pagos = data.data));
                this.$Progress.finish();
            },
            laodPagosFirst(){
                this.$Progress.start();
                axios.get("api/encuesta/selectPagosFirst",{params: {mes: this.$route.query.mes, anio: this.$route.query.anio}})
                .then(({ data }) =>(this.pagosB = data.data));
                this.$Progress.finish();
            },
        },
        mounted() {
            console.log('Component mounted.');
            $('[data-toggle="tooltip"]').tooltip();
        },
        created() {
            this.$Progress.start();
            this.laodPagos();
            this.laodPagosFirst();
            this.$Progress.finish();
        }
    }
</script>
