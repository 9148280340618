export default [
    //{ path: '/registro-general', component: require('./components/RegistroGeneral.vue').default },
    //{ path: '/developer', component: require('./components/Developer.vue').default },
    //{ path: '/products', component: require('./components/product/Products.vue').default },
    //{ path: '/product/tag', component: require('./components/product/Tag.vue').default },
    //{ path: '/product/category', component: require('./components/product/Category.vue').default },
    //{ path: '/dashboard', component: require('./components/Dashboard.vue').default },
    //{ path: '/users', component: require('./components/Users.vue').default },
    //{ path: '/datos', component: require('./components/Datos.vue').default },
    //{ path: '/datosD', component: require('./components/DatosD.vue').default },
    //{ path: '/pagos', component: require('./components/Pagos.vue').default },
    //{ path: '/pagosSR', component: require('./components/pagosSR.vue').default },
    //{ path: '/pagosA', component: require('./components/pagosA.vue').default },
    { path: '/perfil', component: require('./components/Profile.vue').default },
    { path: '/fecha', component: require('./components/Fecha.vue').default },
    { path: '/detalle', component: require('./components/Detalle.vue').default },
    { path: '/pagosR', component: require('./components/pagosR.vue').default },
    { path: '/pagosGeneral', component: require('./components/PagosGeneral.vue').default },
    { path: '*', component: require('./components/NotFound.vue').default }
];
